.blinking_droppable {
  animation: blinker_droppable 1s linear infinite;
  background: #00000033;
}

@keyframes blinker_droppable {
  50% {
    opacity: 0.8;
  }
}

.blinking_droppable_hover {
  background: #00000055;
}

.blinking_pdfviewer_droppable {
  animation: blinker_droppable 1s linear infinite;
  opacity: 0.3;
}

.blinking_pdfviewer_droppable_hover {
  animation: none;
}
