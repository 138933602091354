/** Styles for the comment input where the user can mention other users */

.mentions {
  /** Important, otherwise the general Material line-height will screw things up! */
  line-height: normal;
  max-height: 500px;
}

textarea {
  font-size: 105% !important;
  font-family: 'Open Sans', 'Helvetica';
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

/* .mentions--multiLine .mentions__control {
} */

.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 0px;
}
.mentions--multiLine .mentions__input {
  border: 0px solid #00000022;
  padding: 9px;
  outline: 0;
  max-height: 500px;
  overflow-y: scroll !important;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #d8e4ed;
}

.mentions__mention {
  background-color: 'green';
}
