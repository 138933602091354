a {
  color: #003366;
}

a:visited {
  color: #003366;
}

/* disable browser outline in case of select element */
div:focus {
  outline: none;
}

/* Scrollbar width */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Scrollbar track */
*::-webkit-scrollbar-track {
  background: transparent;
}
/* Scrollbar handle */
*::-webkit-scrollbar-thumb {
  background: #00000044;
  border-radius: 5px;
}
/* Scrollbar handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

/** Scrollbar of the document viewer should be more visible */
/* Document viewer scrollbar width */
#viewerContainer::-webkit-scrollbar {
  width: 12px;
}
/* Document viewer scrollbar track */
#viewerContainer::-webkit-scrollbar-track {
  background: transparent;
}
/* Document viewer scrollbar handle */
#viewerContainer::-webkit-scrollbar-thumb {
  background: #00000044;
  border-radius: 5px;
  /* Depreciated on 2020-12-14 */
  /* min-height: 50px; */
}
/* Document viewer scrollbar handle on hover */
#viewerContainer::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

/* User-selected text background-color */
*::-moz-selection {
  background-color: #ced3de;
}

*::selection {
  background-color: #ced3de;
}

.MuiSvgIcon-root {
  color: #003366;
}
.Mui-disabled .MuiSvgIcon-root {
  color: inherit;
}

.hl {
  background-color: #bdd0f7;
}

.nonbold {
  font-weight: normal !important;
  background-color: #ffffff00;
}

.quickaccessHL {
  color: #003366;
  background-color: #bdd0f7;
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background-color: #003366 !important;
}
.Toastify__toast--success {
  background-color: #003366 !important;
}
.Toastify__toast--warning {
  background-color: #66650a;
}
.Toastify__toast--error {
  background-color: #66150a;
}

/* cursor style for disabled table row */
.MuiTableRow-root.disabled {
  cursor: not-allowed !important;
}

/* class to disable browser selection for element. Used on search result page to use custom text selection. */
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.editext-invisible {
  opacity: 0;
}

.editext-edit-container {
  margin: -4px;
}

.editext-heading {
  font-size: x-large;
  margin: 2px;
  /* TODO: This should not be hardcoded. Find a better way to text and icons to not collide */
  width: 80%;
  cursor: text;
}

.editext-title {
  font-size: large;
  margin: 2px;
  /* TODO: This should not be hardcoded. Find a better way to text and icons to not collide */
  width: 90%;
  cursor: text;
}

/* cursor style for disabled table row */
.MuiTableRow-root.disabled {
  cursor: not-allowed !important;
}
.MuiSwitch-colorPrimary.Mui-checked {
  color: #003366 !important;
}

/** Fixes a weird bug when "everyone" is vieweing a documents list, the document rows become very
thin */
.MuiTableRow-root {
  height: 42px;
}

/** Makes title bar font bigger */
.title-bar > div {
  font-size: 100%;
}

/* Panes viewport scrollbar */

/* Depreciated on 2020-12-17 */
/* #panesViewport::-webkit-scrollbar {
  height: 20px;
} */
/* Panes viewport scrollbar track */
#panesViewport::-webkit-scrollbar-track {
  background: transparent;
}

/* Panes viewport scrollbar handle on hover */
#panesViewport::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

/* clears the ‘X’ in search bars from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ in search bars from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
